import { defineStore } from 'pinia';

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    ipCountry: '' as string,
  }),
  actions: {
    async getIpCountry() {
      const headers = useRequestHeaders();
      this.ipCountry = headers['cf-ipcountry'];
    }
  },
});
