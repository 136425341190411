<template>
  <div
    class="modalBg navHeight fixed w-full top-0 left-0 right-0 z-mobileMenu"
    :class="{
      'pointer-events-none': !uiStore.showMobileMenu,
      'pointer-events-auto bg-overlay': uiStore.showMobileMenu
    }"
  >
    <transition name="mobileMenu">
      <div
        v-if="uiStore.showMobileMenu"
        class="fixed w-full top-0 left-0 navHeight"
        @click.self="closeMenu"
      >
        <div
          class="navArea navHeight navWidth bg-white absolute overflow-auto overflow-x-hidden"
          @scroll.stop
          @touchmove.stop
        >
          <div class="navWidth verticalSpread pt-24 pl-24 pr-24 sm:px-32 flex flex-col">
            <div class="w-full flex justify-end mb-12">
              <button class="w-22 h-22 bg-gray400" @click="closeMenu">
                <img
                  :src="'/icons/icon-close.svg?url'"
                  alt="close"
                  class="w-12"
                  height="12"
                  width="12"
                >
              </button>
            </div>

            <div class="text-heading-large leading-single border-b border-grey200">
              <component
                :is="mainMenu.linkGroups.length ? 'div' : 'a'"
                v-for="(mainMenu, index) in header.mainMenuItems"
                :key="index"
                :href="mainMenu.linkUrl"
                class="block py-10 last:pb-24"
                @click="level1click(index, mainMenu.linkGroups.length > 0)"
              >
                <div class="flex justify-between items-center">
                  <div>{{ mainMenu.linkText }}</div>
                  <div v-if="mainMenu.linkGroups.length">
                    <img
                      :src="'/icons/icon-right.svg?url'"
                      alt="Arrow right"
                      class="h-16 object-contain"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </component>
            </div>

            <div>
              <GlobalsLinkHelper
                v-for="(subMenu, index) in header.subMenuLinks"
                :key="index"
                :to="subMenu.linkUrl"
                class="text-small-regular leading-single block py-10 first:pt-28 last:pb-28"
              >
                <div class="flex justify-between items-center">
                  <div>{{ subMenu.linkText }}</div>
                </div>
              </GlobalsLinkHelper>
            </div>

            <div class="border-t border-grey200 pt-12 mt-auto">
              <a
                v-if="isLoggedIn"
                :href="myAccountPage"
                class="text-xs md:text-xs-medium block py-10 w-full"
              >
                <div class="flex items-center">
                  <img
                    :src="'/heroicons/outline/user.svg?url'"
                    alt="User"
                    class="h-20 mr-12 inline-block"
                    height="20"
                    width="20"
                  >
                  <div v-if="uiStore.hasLoadedCheckLoginStatus" class="mt-2 inline-flex max-w-full truncate">
                    <span v-if="uiStore.userFirstName" class="align-middle truncate">
                      {{ uiStore.userFirstName }}
                    </span>
                    <span v-else class="align-middle truncate">
                      {{ translation.navMyPage }}
                    </span>
                  </div>
                </div>
              </a>

              <button
                v-else
                class="text-xs md:text-xs-medium block py-10 w-full"
                @click="onOpenUserModal(false)"
              >
                <div class="flex items-center">
                  <img
                    :src="'/heroicons/outline/user.svg?url'"
                    alt="User"
                    class="h-20 mr-12 inline-block"
                    height="20"
                    width="20"
                  >
                  <div class="mt-2">{{ translation.navMyPage }}</div>
                </div>
              </button>

              <button
                v-if="uiStore.isLoggedIn"
                class="text-xs md:text-xs-medium block py-10 mb-16 w-full"
                @click="logout(false)"
              >
                <div class="flex items-center">
                  <img
                    :src="'/heroicons/outline/arrow-right-on-rectangle.svg?url'"
                    alt="Log out"
                    class="h-20 mr-12 inline-block"
                    height="20"
                    width="20"
                  >
                  <div class="mt-2">{{ translation2.navLogout }}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <!-- level 2 -->
        <transition name="mobileMenu">
          <div
            v-if="currentMenu && expanded >= 0"
            class="nav2Area navHeight navWidth bg-white absolute right-0 overflow-x-hidden overflow-auto border-l border-grey400"
          >
            <div class="navWidth p-28">
              <div class="w-full flex justify-between mb-28">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium flex items-center" @click="expanded = -1">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    alt="Navigate left"
                    class="h-16 inline-block"
                    height="16"
                    width="16"
                  >
                  <span class="inline-block ml-12 relative top-2">{{ translation.navBack }}</span>
                </button>
                <button class="w-22 h-22 bg-gray400" @click="closeMenu">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <GlobalsLinkHelper
                v-if="currentMenu.buttonText"
                class="btn btn--secondary btn--sm w-full mb-24"
              >
                {{ currentMenu.buttonText }}
              </GlobalsLinkHelper>
              <div class="mb-32">
                <div
                  v-for="(n2, n2index) in currentMenu.linkGroups"
                  :key="expanded + ':' + n2index"
                  class="sm:mb-32"
                  :class="{
                    'mb-32': n2.heading
                  }"
                >
                  <div class="text-xsmall-medium mb-16">{{ n2.heading }}</div>

                  <GlobalsLinkHelper
                    v-for="(n3, n3index) in n2.links"
                    :key="expanded + ':' + n2index + ':' + n3index"
                    :to="n3.linkUrl"
                    class="text-xsmall block mb-16 underline linkUnderline"
                  >
                    {{ n3.linkText }}
                  </GlobalsLinkHelper>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { computed, ref } from 'vue';
import { useUiStore } from '~/store/ui';
import { type  IHeaderMenuItem } from '~/api-types';
import useLogin from '~/composables/useLogin';
import { storeToRefs } from 'pinia';
const { logout } = useLogin();
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.menuResources;
const translation2 = globalContentStore.myPageResources;
const myAccountPage = globalContentStore.pageSettings.myAccountPage.url;

const uiStore = useUiStore();
const header = useGlobalContentStore().header;
const expanded = ref(-1);
const { isLoggedIn } = storeToRefs(uiStore);

const level1click = (index: number, hasChildren = false) => {
  if (hasChildren) {
    expanded.value = index;
  } else {
    closeMenu();
  }
};

const closeMenu = () => {
  uiStore.setShowMobileMenu(false);
};

// close menu when route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    expanded.value = -1;
    closeMenu();
  }
);

const currentMenu = computed<IHeaderMenuItem | null>(()=> {
  if (expanded.value > -1 && header.mainMenuItems[expanded.value]) {
    return header.mainMenuItems[expanded.value];
  }
  return null;
});

const onOpenUserModal = (preventLoginNavigate: boolean) => {
  closeMenu();
  uiStore.setShowLogin(true);
  if (preventLoginNavigate) {
    uiStore.setPreventLoginNavigate(true);
  }
};

</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.modalBg {
  transition: all 0.4s ease-out;
}
.navHeight {
  height: calc(var(--vh) * 100);
}
.navWidth {
  width: 85vw;
}
.navArea, .nav2Area {
  left: 15vw;
  overscroll-behavior: contain;
}
.nav2Area {
  transform: translateX(0%);

}
.verticalSpread {
  height: calc(var(--vh) * 100);
}

.mobileMenu-enter-active {
  transition: all 0.25s ease-out;
}
.mobileMenu-leave-active {
  transition: all 0.25s ease-in;
}

.mobileMenu-enter-from,
.mobileMenu-leave-to {
  transform: translateX(100%);
}

.linkUnderline {
  text-underline-offset: 3px;
}

</style>
