<template>
  <ModalWrapper
    v-if="showLogin"
    :headline="headline"
    :closeable="true"
    :backbutton="loginType !== 'login' && loginType !== 'signupComplete'"
    content-class=""
    @close="hideModal"
    @back="showSignin"
  >
    <!-- login -->
    <div v-if="loginType === 'login'">
      <Login :remove-padding="true" />
    </div>
    <!-- forgot password -->
    <ResetPassword v-if="loginType === 'reminder'" :prefill="username" />
    <!-- signup -->
    <SignUp v-if="loginType === 'signup' || loginType === 'signupComplete'" />
  </ModalWrapper>
</template>

<script setup lang="ts">
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import { storeToRefs } from 'pinia';
import SignUp from '~/components/login/SignUp.vue';
import ResetPassword from '~/components/login/ResetPassword.vue';
import Login from '~/components/login/Login.vue';
import { usePageContentStore } from '~/store/pageContent';
import { ref } from 'vue';
import useDisableScroll from '~/composables/useDisableScroll';

const { enableScroll } = useDisableScroll();
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.myPageResources;
const myAccountPage = globalContentStore.pageSettings.myAccountPage.url;
const uiStore = useUiStore();
const { loginType, showLogin } = storeToRefs(uiStore);
const username = ref('');
const returnUrl = ref('');

returnUrl.value = myAccountPage ?? '/';

const pageContentStore = usePageContentStore();
const { isMyPage } = storeToRefs(pageContentStore);

const showSignin = () => {
  uiStore.setLoginType('login');
};

const hideModal = () => {
  uiStore.setShowLogin(false);
  uiStore.setLoginType('login');
  uiStore.checkLoggedIn();
  enableScroll();
  if (isMyPage.value) {
    navigateTo('/', { replace: true });
  }
};

const headline = computed<string>(()=>{
  switch (loginType.value) {
    case 'signup':
      return translation.loginModalTitleSignup;
    case 'reminder':
      return translation.loginModalTitleReminder;
    case 'signupComplete':
      return translation.loginModalTitleComplete;
    default:
      return translation.loginModalTitleLogin;
  }
});

</script>
