import { defineStore } from 'pinia';
import type { IUserSettings } from '~/api-types';
const { apiGet } = useApiFetch();

export const useImpersonateStore = defineStore('impersonateStore', {
  state: () => ({
    email: '' as string,
  }),
  actions: {
    async fetchUser() {
      const query = 'user/settings';
      const result = await apiGet<IUserSettings>(query);
      if (result) {
        this.email = result.email;
      }
    },
  },
});
