<template>
  <div>
    <SignUpVerifyEmailInformation
      v-if="showVerifyEmailConfirmation"
      :is-in-modal="true"
    />
    <div v-else class="-mx-32 sm:mx-0">
      <div
        class="pb-24 text-xsmall sm:px-0 sm:text-small-regular"
        :class="{
          'px-24': !removePadding
        }"
      >
        <div class="px-24 sm:px-0">
          <div class="text-xsmall-medium mb-12 sm:text-small-medium">{{ translation.loginTitle }}</div>
          <form @submit.prevent="submitLogin">
            <div class="mb-12">
              <input
                v-model="username"
                type="email"
                autocomplete="email"
                class="form-input"
                :placeholder="translation.loginEmail"
                :class="{
                  'has-error form-input--error': usernameError,
                }"
                @input="usernameError = ''"
              >
              <div v-if="usernameError" class="mt-6 text-xsmall-medium text-error">
                {{ usernameError }}
              </div>
            </div>
            <div class="mb-12">
              <div class="relative">
                <input
                  v-model="password"
                  :type="showPassword ? 'text': 'password'"
                  autocomplete="password"
                  :placeholder="translation.loginPassword"
                  class="form-input"
                  :class="{
                    'has-error form-input--error': passwordError,
                  }"
                  @input="passwordError = ''"
                >
                <div
                  class="absolute top-0 right-0 h-40 w-40 flex items-center justify-center sm:h-48 sm:w-48"
                  @click.stop="toggleShowPassword"
                >
                  <img
                    :src="showPassword ? '/heroicons/outline/eye-slash.svg' : '/heroicons/outline/eye.svg'"
                    alt="close"
                    class="inline-block h-20 w-20"
                    height="20"
                    width="20"
                  >
                </div>
              </div>
              <div v-if="passwordError" class="mt-6 text-xsmall-medium text-error">
                {{ passwordError }}
              </div>
            </div>
            <GlobalsButton
              :text="translation.loginAction"
              :no-validate="true"
              :loading="loadLogin"
              class="mb-16 w-full"
            />
          </form>
          <div
            v-if="generalError"
            class="bg-error mb-8 rounded-md text-white p-12 flex items-start text-xsmall sm:text-small-regular"
          >
            <ExclamationCircleIcon
              class="w-24 text-white mr-12 flex-shrink-0"
            />
            <div class="mt-[5px] md:mt-2">
              {{ generalError }}
              <div
                v-if="additionalErrorMessage"
                class="underline hover:no-underline inline cursor-pointer"
                @click="resendVerifyEmailClick"
              >
                {{ additionalErrorMessage }}
              </div>
            </div>
          </div>
          <button class="underline text-left text-xsmall-medium" @click="showForgotPassword">
            {{ translation.loginForgot }}
          </button>
        </div>
      </div>
      <EpiserverContentArea
        v-if="loginPage?.mainContent"
        :model="loginPage?.mainContent"
        class="content-area-login overflow-x-hidden"
        :full-width="true"
      />
      <div class="px-24 sm:px-0">
        <div
          class="text-xsmall sm:px-0"
          :class="{
            'px-24': !removePadding
          }"
        >
          <div class="bg-grey200 rounded-md p-16 sm:p-24">
            <div
              class="wysiwyg wysiwyg--tiny-text checkmarkUl mb-16"
              v-html="loginSettings.createAccountCta"
            />
            <button class="btn btn--primary w-full btn--sm h-40 sm:h-48" @click="showSignup">
              {{ translation.signupAction }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ExclamationCircleIcon from '@heroicons/vue/24/outline/esm/ExclamationCircleIcon';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import { storeToRefs } from 'pinia';
import type { IICommandResultBase } from '~/api-types';
import { ref } from 'vue';
import SignUpVerifyEmailInformation from '~/components/modals/SignUpVerifyEmailInformation.vue';
import { ContentResolver } from '@episerver/content-delivery';

const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.myPageResources;
const translation2 = globalContentStore.sharedResources;
const myAccountPage = globalContentStore.pageSettings.myAccountPage.url;
const uiStore = useUiStore();
const { preventLoginNavigate } = storeToRefs(uiStore);
const loginSettings = globalContentStore.loginSettings;
const marketSettings =globalContentStore.marketSettings;
const username = ref('');
const password = ref('');
const showPassword = ref(false);
const usernameError = ref('');
const passwordError = ref('');
const returnUrl = ref('');
const loadLogin = ref(false);
const { apiPost } = useApiFetch();
const generalError = ref('');
const additionalErrorMessage = ref('');
const showVerifyEmailConfirmation = ref(false);
const loginPage = ref();
const { enableScroll } = useDisableScroll();

returnUrl.value = myAccountPage ?? '/';

const props = defineProps<{
  preventLoginNavigate?: boolean,
  removePadding?: boolean
}>();

const emit = defineEmits<{
  (event: 'on-login'): void,
}>();

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const showSignup = () => {
  uiStore.setLoginType('signup');
  uiStore.setShowLogin(true);
};

const showForgotPassword = () => {
  uiStore.setLoginType('reminder');
  uiStore.setShowLogin(true);
};

const submitLogin = async() => {
  usernameError.value = '';
  passwordError.value = '';

  if (!username.value) {
    usernameError.value = translation2.emptyEmailError;
    return;
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username.value.trim())) {
    usernameError.value = translation2.invalidEmail;
    return;
  }

  if (!password.value) {
    passwordError.value = translation2.emptyPasswordError;
    return;
  }

  loadLogin.value = true;

  const result = await apiPost<IICommandResultBase>(
    'user/session',
    {
      countryCode: marketSettings.countryCode,
      email: username.value,
      password: password.value,
    }
  );
    
  if (result && result.success) {
    loadLogin.value = false;
    // navigateTo(myAccountPage, { replace: true }); throws error
    if (!preventLoginNavigate.value && !props.preventLoginNavigate) {
      window.location.replace(myAccountPage);
    }
    uiStore.setShowLogin(false);
    uiStore.setIsLoggedIn(true);
    uiStore.dataLayerPushOnLogin();
    await uiStore.checkLoggedIn();
    emit('on-login');
    enableScroll();
  } else if (result) {
    loadLogin.value = false;
    
    if (result.errorMessage == null && result.validationErrors.length > 0)
    {
      result.errorMessage = result.validationErrors[0].displayMessage;
    }
    generalError.value = result.errorMessage || 'error';

    if (result.userNotVerified) {
      additionalErrorMessage.value = translation2.resendVerificationEmail;
    }

    window.dataLayer?.push({
      event: 'Error',
      errorMessage: result.errorMessage || 'login error',
    });
  } else {
    loadLogin.value = false;
    generalError.value = 'error';
  }

};

const resendVerifyEmailClick = async() => {
  const payload = {
    email: username.value,
    userId: '',
    returnUrl: '/',
  };
 
  const result = await apiPost<IICommandResultBase>(
    'user/resendverification',
    payload
  );
  
  if (result && result.success) {
    showVerifyEmailConfirmation.value = true;
  }
  if (result && !result.success) {
    window.dataLayer?.push({
      event: 'Error',
      errorMessage: 'Resend verification email error',
    });
  }
};

onMounted(async()=> {
  if (globalContentStore.pageSettings.loginPage?.url) {
    const contentResolver = new ContentResolver();
    const contentResult = await contentResolver.resolveContent(globalContentStore.pageSettings.loginPage.url, true);
    loginPage.value = contentResult.content;
  }
});
</script>

<style>
.content-area-login {
  .layout-container {
    @apply px-24 sm:px-0 mb-24 md:mb-32;

    .wysiwyg--block {
      .text-heading-xl {
        margin-bottom: 0.8rem;
      }
      p {
        @apply w-full;
      }
    }

    .swiper-customer-service-cards {
      @apply !px-0 md:!px-0;

      .swiper-wrapper {
        @apply grid-cols-2 gap-12 grid;

        .swiper-slide {
          @apply !mb-0;
        }

        .item {
          @apply pt-48 sm:pt-56 text-center sm:p-16;
        }

        .icon {
          @apply top-16 left-1/2 transform -translate-x-1/2;
        }
      }
    }

    .swiper-mobile {
      @apply !px-24 sm:!px-0;
      .swiper-wrapper {
        @apply flex gap-0 sm:grid-cols-2 sm:gap-12 sm:grid px-0;
      }
    }

    &.customer-service-card-block.use-slider {
      @apply !px-0 sm:!px-24 md:!px-0;
    }
  }
}
</style>
