<template>
  <div
    class="layout-container scroll-to-top-block"
    :class="{
      'bg-grey200': isCheckout || isMyPages,
    }"
  >
    <button
      class="btn btn--secondary btn--sm w-full mb-8 md:mb-24 text-xs"
      @click="scrollToTop()"
    >
      <ArrowUpIcon class="mr-8 w-12 mb-px" />
      {{ sharedResources.scrollToTopText }}
    </button>
  </div>
</template>

<script setup lang="ts">
import ArrowUpIcon from '@heroicons/vue/24/outline/esm/ArrowUpIcon';
import { useGlobalContentStore } from '~/store/globalContent';
import { usePageContentStore } from '~/store/pageContent';
const pageStore = usePageContentStore();
const pageType = ref(useGlobalContentStore().pageType);
const sharedResources = useGlobalContentStore().sharedResources;

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const isCheckout = computed(() => {
  return pageType.value === 'CheckoutPage';
});

const isMyPages = computed(() => {
  return pageType.value === 'MyAccountPage';
});
watch(
  () => pageStore.pageType,
  () => {
    pageType.value = pageStore.pageType.slice(-1)[0];
  }
);

</script>
