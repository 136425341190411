<template>
  <div>
    <ModalWrapper
      v-if="modalOpen"
      :headline="sharedResources.newsletterModalHeading"
      :closeable="true"
      content-class=""
      @close="hideModal"
    >
      <div v-if="newsletterAccepted">
        <div
          class="wysiwyg"
          v-html="newsletterSettings.confirmationMessage"
        />
      </div>
      <div v-else>
        <div class="flex text-heading-xl mb-8">
          <EnvelopeIcon class="h-24 w-24 mr-12" />
          {{ newsletterSettings.modalTitle }}
        </div>

        <div class="wysiwyg wysiwyg--sm-text mb-16" v-html="newsletterSettings.modalMainBody" />
        <TextInput
          :id="'discountCodeInput'"
          :placeholder="'E-postadress'"
          :form-input="true"
          :error="emailError"
          :init-value="defaultValue"
          class="w-full"
          @submit-input="emailInput"
          @enter-submit="submitNewsletterSubscription"
        />
        <div
          v-if="wrongEmail"
          class="mb-12 cursor-pointer"
          @click="fixEmail"
          v-html="wrongEmail"
        />
        <button class="btn btn--primary w-full" @click="submitNewsletterSubscription">
          {{ sharedResources.newsletterModalCta }}
        </button>
        <div class="wysiwyg wysiwyg--tiny-text mt-8" v-html="newsletterSettings.termsAndConditions" />
      </div>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import EnvelopeIcon from '@heroicons/vue/24/outline/esm/EnvelopeIcon';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import TextInput from '~/components/form-elements/TextInput.vue';
import { ref } from 'vue';
import emailMisspelled, { top100 } from 'email-misspelled';
import { useErrorStore } from '~/store/error';
import { useVoyadoStore } from '~~/store/voyado';
import { storeToRefs } from 'pinia';
import useDisableScroll from '~/composables/useDisableScroll';
import { type IICommandResultBase } from '~/api-types';
const { disableScroll, enableScroll } = useDisableScroll();
const emailChecker = emailMisspelled({ domains: top100 });
const uiStore = useUiStore();
const { contactId } = storeToRefs(uiStore);
const errorStore = useErrorStore();
const globalContentStore = useGlobalContentStore();
const voyadoStore = useVoyadoStore();
const { apiPost } = useApiFetch();
const actionButtonClicked = ref(false);

interface Props {
  clickCounter?: number,
}

const props = withDefaults(defineProps<Props>(), {
  clickCounter: 0,
});

onMounted(() => {
  const buttons = document.querySelectorAll('.btn--primary');
  buttons.forEach(button => {
    button.addEventListener('click', () => {
      actionButtonClicked.value = true;
    });
  });
  document.body.addEventListener('click', (event) => {
    const clickedElement = event.target;
    if (
      clickedElement &&
      !clickedElement.classList.contains('btn--primary') &&
      !(clickedElement.parentElement && clickedElement.parentElement.classList.contains('btn--primary'))
    ) {
      actionButtonClicked.value = false;
    }
  });
});

const sharedResources = globalContentStore.sharedResources;

const newsletterSettings = globalContentStore.newsletterSettings;
const newsletterAccepted = ref(false);

const newsletterClosedCookie = useCookie('newsletter-closed-cookie', {
  maxAge: 3 * 60 * 60 * 24,
});

const modalOpen = computed(() => {
  if (uiStore.showNewsletterModal) disableScroll();
  return uiStore.showNewsletterModal;
});

const hideModal = () => {
  uiStore.setShowNewsletterModal(false);
  newsletterClosedCookie.value = 'true';
  enableScroll();
};

const email = ref('');
const emailError = ref('');
const defaultValue = ref('');

const wrongEmail = ref('');
const suggestion = ref('');
const checkEmail = () => {
  const test = emailChecker(email.value);
  if (test.length && test[0].corrected) {
    wrongEmail.value = `Menade du <span class="underline">${test[0].corrected}`;
    suggestion.value = test[0].corrected;
  }
};
const fixEmail = () => {
  email.value = suggestion.value;
  defaultValue.value = suggestion.value;
  wrongEmail.value = '';
};

const emailInput = (input: string) => {
  email.value = input;

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
    checkEmail();
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = sharedResources.invalidEmail;
  }
};

const submitNewsletterSubscription = async() => {
  if (!email.value) {
    emailError.value = sharedResources.emptyEmailError;
  }

  if (!emailError.value && email.value) {
    const res = await apiPost<IICommandResultBase>(
      'newsletter',
      {
        countryCode: globalContentStore.marketSettings.countryCode,
        email: email.value,
      }
    );

    if (res.success) {
      newsletterAccepted.value = true;
  
      window.dataLayer?.push({
        event: 'newsletter_signup',
      });
  
      await uiStore.checkLoggedIn();
      voyadoStore.setContactId(contactId.value);
    } else {
      errorStore.displayError({ message: res.errorMessage });
    }
  }
};

watch(
  () => props.clickCounter,
  () => {
    if (
      newsletterSettings.clicksToTrigger != null &&
      props.clickCounter >= newsletterSettings?.clicksToTrigger &&
      !uiStore.userNewsletterStatus &&
      !newsletterClosedCookie.value &&
      !actionButtonClicked.value
    ) {
      setTimeout(() => {
        uiStore.setShowNewsletterModal(true);
      }, 1000);
    }
  }
);

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#00374a',
    },
  ],
});

</script>
