<template>
  <div
    class="search-result-header fixed md:absolute bg-white z-30 w-full h-[calc(100%-112px)] sm:h-[calc(100%-114px)] bottom-0 md:bottom-auto right-0 overflow-auto md:right-auto md:h-auto md:w-full md:z-[1] md:max-h-[80vh] md:rounded-b-md md:border-t border-grey400"
    :style="{ 'top': topValue + 'px' }"
  >
    <div
      class="p-24"
      @touchstart="blurInput()"
      @touchend="blurInput()"
    >
      <div v-if="showPopularPhrases">
        <div
          class="text-xsmall sm:text-small-regular mb-12 text-grey800 sm:text-black"
        >
          {{ translation.popularPhrases }}
        </div>
        <div v-if="searchSettings.popularSearchLinks && searchSettings.popularSearchLinks.length > 0">
          <nuxt-link
            v-for="(link, index) in searchSettings.popularSearchLinks"
            :key="index"
            :to="link.linkUrl"
            class="text-xsmall-medium sm:text-small-medium mr-16 mb-8 block mb-12 sm:inline-block sm:mb-0"
            @click="onSearchClick(link.linkUrl, true), $emit('close-search-header')"
          >
            <span>{{ link.linkText }}</span>
          </nuxt-link>
        </div>
      </div>
      <div
        v-if="showEmptyResult"
        class="flex flex-col md:flex-row"
      >
        <div class="flex-shrink-0 md:w-[190px]">
          <div class="mb-24 text-xsmall sm:text-small-regular text-grey800 sm:text-black">{{ translation.brands }} (0)</div>
          <div class="text-xsmall sm:text-small-regular text-grey800 sm:text-black hidden md:block">{{ translation.otherHits }} (0)</div>
        </div>
        <div class="w-full mb-24 md:mb-0">
          <div class="mb-16">
            <div class="text-xsmall sm:text-small-regular text-grey800 sm:text-black">{{ translation.products }} (0)</div>
          </div>
          <div class="border-2 border-dashed border-grey200 w-full flex items-center justify-center p-24 sm:py-48">
            <WysiwygWrapper
              v-if="searchSettings.emptySearchResultCta"
              :style-config="{
                'wysiwyg wysiwyg--sm wysiwyg--sm-text': true,
              }"
              :html="searchSettings.emptySearchResultCta"
            />
          </div>
        </div>
        <div class="text-xsmall sm:text-small-regular text-grey800 sm:text-black md:hidden">{{ translation.otherHits }} (0)</div>
      </div>
      <div
        v-if="showResults"
        class="flex flex-col md:flex-row"
      >
        <div class="md:flex-shrink-0 md:w-[110px] lg:w-[130px] md:order-last md:ml-24">
          <div class="mb-24">
            <div class="mb-12 text-xsmall sm:text-small-regular text-grey800 sm:text-black">{{ translation.brands }} ({{ searchResults.brands.length }})</div>
            <GlobalsLinkHelper
              v-for="(link, index) in searchResults.brands.slice(0,10)"
              :key="index"
              :to="link.url"
              class="text-small-medium block mb-12 last:mb-0"
              @click="$emit('close-search-header')"
            >
              {{ link.name }}
            </GlobalsLinkHelper>
          </div>
          <div class="hidden md:block">
            <div class="mb-12 text-xsmall sm:text-small-regular text-grey800 sm:text-black truncate">{{ translation.otherHits }} ({{ searchResults.content.length }})</div>
            <GlobalsLinkHelper
              v-for="(link, index) in searchResults.content.slice(0,10)"
              :key="index"
              :to="link.url"
              class="text-xsmall-medium sm:text-small-medium block mb-12 last:mb-0 truncate"
              @click="$emit('close-search-header')"
            >
              {{ link.name }}
            </GlobalsLinkHelper>
          </div>
        </div>
        <div class="w-full">
          <div class="mb-16">
            <div class="text-xsmall sm:text-small-regular text-grey800 sm:text-black">{{ translation.products }} ({{ searchResults.products.length }})</div>
          </div>
          <div
            class="grid grid-cols-5 gap-24 hidden md:grid md:gap-x-16 md:grid-cols-3"
          >
            <ProductSpot
              v-for="(product, index) in searchResults.products.slice(0, 6)"
              :key="index"
              :product="product"
              :show-excerpt="false"
              :show-quickbuy="false"
              :slim-layout="true"
              :small-layout="true"
              :large="false"
              :search-layout="true"
              @click="onSearchClick(product.url, false), $emit('close-search-header')"
            />
          </div>
          <div v-if="searchResults" class="block mb-24 md:hidden">
            <nuxt-link
              v-for="(product, index) in searchResults.products.slice(0, isMobile ? 6 : 10)"
              :key="index"
              :to="product.url"
              class="flex items-center mb-12"
              @click="onSearchClick(product.url, false), $emit('close-search-header')"
            >
              <div class="h-[64px] w-[64px] flex-shrink-0 rounded flex justify-center items-center bg-grey200 mr-12">
                <nuxt-img
                  v-if="product.imageUrl"
                  provider="norce"
                  :src="product.imageUrl"
                  preset="standard"
                  class="h-40 w-40 object-fit"
                />
              </div>
              <div class="w-full text-xsmall-medium">
                {{ product.name }}
              </div>
            </nuxt-link>
          </div>
          <div v-if="searchResults" class="md:hidden pb-88">
            <div class="mb-12 text-xsmall sm:text-small-regular text-grey800 sm:text-black">{{ translation.otherHits }} ({{ searchResults.content.length }})</div>
            <GlobalsLinkHelper
              v-for="(link, index) in searchResults.content.slice(0, isMobile ? 6 : 10)"
              :key="index"
              :to="link.url"
              class="text-xsmall-medium sm:text-small-medium block mb-12 last:mb-0"
              @click="$emit('close-search-header')"
            >
              {{ link.name }}
            </GlobalsLinkHelper>
          </div>
        </div>
      </div>
      <div
        v-if="showResults"
        class="fixed w-full right-0 md:w-full bottom-0 py-16 px-24 border-t border-grey400 md:p-0 md:static md:border-none bg-white"
      >
        <nuxt-link
          v-if="searchResultsPage"
          :to="searchResultsPage.url + '?query=' + searchPhrase"
          class="btn btn--secondary h-40 sm:h-48 w-full md:mt-24"
          @click="$emit('close-search-header')"
        >
          {{ translation.showAllHits }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from '~/components/form-elements/TextInput.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';
import { computed, ref } from 'vue';
import { type ISearchResults } from '~/api-types';
import ProductSpot from '~/components/product/ProductSpot.vue';
import { storeToRefs } from 'pinia';

const header = useGlobalContentStore().header;
const globalContentStore = useGlobalContentStore();
const marketSettings = globalContentStore.marketSettings;
const searchSettings = globalContentStore.searchSettings;
const searchResultsPage = globalContentStore.pageSettings?.searchResultsPage;
const searchPhrase = ref('');
const showPopularPhrases = ref(true);
const showEmptyResult = ref(false);
const showResults = ref(false);
const searchResults = ref<ISearchResults>();
const loading = ref(false);
const { isMobile, isTablet } = storeToRefs(useUiStore());
const translation = globalContentStore.searchResources;
const { apiGet } = useApiFetch();

const emit = defineEmits<{
  (event: 'close-search-header'): void,
  (event: 'set-loading'): void,
  (event: 'stop-loading'): void,
}>();

defineProps<{
  siteNoticeBannerHeight?: number
}>();

const topValue = computed(() => {
  return 40;
  // return props.siteNoticeBannerHeight ? Number(props.siteNoticeBannerHeight) + 70 : 70;
});

const blurInput = () => {
  if (document && document.activeElement) {
    document.activeElement?.blur();
  }
};

const performSearch = (async(input: string) => {
  loading.value = true;
  searchPhrase.value = input;
  if (!input || input.length < 2) {
    showPopularPhrases.value = true;
    showEmptyResult.value = false;
    showResults.value = false;
  } else {
    emit('set-loading');

    const searchTerm = `search?Query=${input}&Page=1&PageSize=100&CountryCode=${marketSettings.countryCode}`;
    const search = await apiGet<ISearchResults>(searchTerm);
    if (search) {
      searchResults.value = search;
      if (searchResults.value.totalHits < 1) {
        showPopularPhrases.value = false;
        showResults.value = false;
        showEmptyResult.value = true;
      } else if (searchResults.value.totalHits > 0) {
        showPopularPhrases.value = false;
        showEmptyResult.value = false;
        showResults.value = true;
      }
    }

    emit('stop-loading');
  }
  loading.value = false;

  window.dataLayer?.push({
    'event':'siteSearch',
    'searchTerm': input,
    'searchResults': searchResults.value ? searchResults.value.totalHits : null,
  });
});

defineExpose({
  performSearch,
});

const setSearchPhrase = (input: string) => {
  searchPhrase.value = input;
  performSearch(searchPhrase.value);
};

const onSearchClick = (url: string | undefined, isPopularTerm: boolean) => {
  window.dataLayer?.push({
    'event': 'clickedSearchResult',
    'clickedSearch': url,
    'resultCategory': isPopularTerm ? 'quick suggestion' : 'search result',
  });
  
};

</script>

<style scoped>
.search-gradient:after {
  content: '';
  background-image: linear-gradient(
    180deg,
    var(--fstart, #ffffff) 0%,
    var(--fstart, #ffffff) 50%,
    var(--fmiddle, #ffffffcc) 70%,
    var(--fend, #ffffff00) 100%
  );
  @apply h-36 w-full absolute block;
}

.search-result-header {
  @media screen and (max-width: 1024px) {
    top: auto !important;
  }
}
</style>
