<template>
  <div
    class="MenuDesktop mobileOnly:hidden w-full absolute h-56 -bottom-56"
    @mouseleave="expanded = -1"
  >
    <div
      class="navShadow bg-[#FAFAFA] py-16 border-b border-grey400 w-full relative z-above"
    >
      <div class="row1 layout-container flex justify-between">
        <div>
          <GlobalsLinkHelper
            v-for="(mainMenu, index) in header.mainMenuItems"
            :key="index"
            :to="mainMenu.linkUrl"
            class="text-small-medium mr-20 linkUnderline"
            :class="{'underline': mainMenu.underline}"
            @mouseenter="expand(index, mainMenu.linkGroups.length)"
          >
            {{ mainMenu.linkText }}
          </GlobalsLinkHelper>
        </div>
        <div>
          <GlobalsLinkHelper
            v-for="(subMenu, index) in header.subMenuLinks"
            :key="index"
            :link-item="subMenu"
            class="text-small-regular ml-20"
          />
        </div>
      </div>
    </div>
    <div
      v-for="(subMenu, index) in header.mainMenuItems"
      :key="index"
    >
      <transition name="slide-fade">
        <div
          v-show="currentMenu && expanded >= 0 && expanded === index"
          class="row2 absolute bg-white py-18 border-b border-grey400 w-full"
        >
          <div
            class="grid grid-cols-6 mb-8 layout-container"
          >
            <div
              v-for="(n2, n2index) in subMenu?.linkGroups"
              :key="expanded + ':' + n2index"
              class="pt-12 pr-18"
            >
              <div class="text-small-medium mb-16">{{ n2.heading }}</div>

              <GlobalsLinkHelper
                v-for="(n3, n3index) in n2.links"
                :key="expanded + ':' + n2index + ':' + n3index"
                :to="n3.linkUrl"
                class="text-small-regular block mb-16 linkUnderline underline"
              >
                {{ n3.linkText }}
              </GlobalsLinkHelper>
            </div>
          </div>
          <div
            v-if="subMenu?.buttonText && subMenu.buttonText !== ''"
            class="layout-container text-center"
          >
            <GlobalsLinkHelper
              class="btn btn--primary inline-block mx-auto"
              :to="subMenu.buttonUrl"
            >
              {{ subMenu.buttonText }}
            </GlobalsLinkHelper>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { computed, ref } from 'vue';
import type { Ref } from 'vue';
import type { IHeaderMenuItem } from '~/api-types';
const header = useGlobalContentStore().header;

const expanded = ref(-1);

const currentMenu = computed<IHeaderMenuItem | null>(()=> {
  if (expanded.value > -1 && header.mainMenuItems[expanded.value]) {
    return header.mainMenuItems[expanded.value];
  }
  return null;
});

const timer: Ref<any> = ref(null);

const expand = (index:number, children = 0) => {
  if (children > 0) {
    clearTimeout(timer.value);
    expanded.value = index;
  } else {
    timer.value = setTimeout(()=> { expanded.value = -1; }, 600);
  }
};

// close menu when route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    clearTimeout(timer.value);
    expanded.value = -1;
  }
);

</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.navShadow {
  box-shadow: 0 0.5px 0 #00000033;
}
.linkUnderline {
  text-underline-offset: 3px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.35s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
