<template>
  <div v-if="quickMenu.showQuickMenu" class="quickMenu sticky z-header bg-white bottom-0 h-60 flex items-center md:hidden">
    <LinkHelper
      v-for="(link, index) in quickMenu.links"
      :key="index"
      :to="link.linkUrl"
      class="grow-0 shrink-0 text-xxs text-center text-grey600"
      :class="[ width ]"
      @click="trackClick(link.linkText, link.linkUrl)"
    >
      <img
        v-if="link.icon"
        :src="icon(link.icon)"
        class="h-20 inline-block mb-2 object-contain"
        :alt="link.linkText"
        height="20"
        width="20"
      >
      <div v-else class="h-20 w-4" />
      <div>{{ link.linkText }}</div>
    </LinkHelper>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import LinkHelper from '~/components/globals/LinkHelper.vue';
import { computed } from 'vue';

const quickMenu = useGlobalContentStore().quickMenu;

const icon = (icon: string) => {
  let iconOut;
  switch (icon) {

    case 'Adjustments horizontal':
      iconOut = '/heroicons/outline-grey600/adjustments-horizontal.svg';
      break;
    case 'Arrow path':
      iconOut = '/heroicons/outline-grey600/arrow-path.svg';
      break;
    case 'Gear':
      iconOut = '/heroicons/outline-grey600/cog-6-tooth.svg';
      break;
    case 'Globe':
      iconOut = '/heroicons/globe-alt.svg';
      break;
    case 'Help':
      iconOut = '/heroicons/outline-grey600/question-mark-circle.svg';
      break;
    case 'Mail':
      iconOut = '/heroicons/outline-grey600/envelope.svg';
      break;
    case 'Pin':
      iconOut = '/heroicons/outline-grey600/map-pin.svg';
      break;
    case 'snus-box':
      iconOut = '/icons/icon-can-gray600.svg';
      break;
    case 'Squares':
      iconOut = '/heroicons/outline-grey600/squares-2x2.svg';
      break;

    default:
      iconOut = `/heroicons/outline-grey600/${icon.toLowerCase()}.svg`;
  }
  return iconOut;
};

const trackClick = (title: string, url: string) => {
  window.dataLayer?.push({
    event: 'quickMenu',
    linkClicked: title,
    urlClicked: url,
  });
};

/*
Adjustments horizontal (heter 'adjustments-horizontal' i Hero icons)
Arrow path (heter 'arrow-path' i Hero iconsl)
Gear (heter ‘cog-6-tooth’ i Hero icons)
Globe (heter 'globe-alt i Hero icons)
Help (heter ‘question-mark-circle’ i Hero icons)
Mail (heter ‘envelope’ i Hero icons)
Pin (heter 'map-pin' i Hero icons)
Snus box (custom logotyp, finns här: Icons )
Squares (heter 'squares-2x2 i Hero icons)
 */

const width = computed(()=> {
  return quickMenu.links.length > 1 ? `basis-1/${quickMenu.links.length}`:'';
});
</script>

<style scoped lang="postcss">
.quickMenu {
  box-shadow: 0px -0.5px 0px #0000001A;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}
</style>
