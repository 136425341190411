<template>
  <div class="footer">
    <div class="bg-grey200">
      <div
        class="py-24 xl:border-b border-grey400"
      >
        <div class="layout-container">
          <img
            :src="footer.logoUrl"
            class="w-112 xl:w-144 object-contain flex-shrink-0 mr-24 hidden xl:block relative mb-20"
            alt="Swedish Match logo"
            height="23"
            width="144"
          >
        </div>
        <div class="layout-container xl:flex justify-between">
          <div class="border-b border-grey400 xl:border-none xl:flex xl:items-end xl:h-24">
            <LinkHelper
              v-for="(footerLink, index) in footer.footerLinks"
              :key="index"
              :link-item="footerLink"
              class="text-xs sm:text-small-regular mr-20 block mb-16 xl:mb-0 hover:underline"
            />
            <a
              v-if="isOneTrustLoaded"
              id="ot-sdk-btn"
              data-onetrust="v3"
              rel="noopener noreferrer"
              class="text-xs sm:text-small-regular mr-20 block mb-16 xl:mb-0 cursor-pointer hover:underline optanon-toggle-display"
            >
              {{ sharedResources.cookieSettings }}
            </a>
          </div>
        </div>
      </div>
      <div class="layout-container pb-32 xl:py-24">
        <div class="xl:flex justify-between items-center">
          <div class="hidden xl:block text-xs wysiwyg wysiwyg--tiny-text" v-html="footer.copyrightText" />
          <div class="leading-none xl:text-right">
            <img
              v-for="footerIcon in footer.footerIconUrls"
              :key="footerIcon"
              :src="footerIcon"
              class="object-contain inline-block max-h-24 max-w-56 sm:max-h-28 sm:max-w-64 mr-16 xl:mr-24 last:mr-0 mb-6 md:mb-0 !w-auto"
              alt="Footer logo"
              height="28"
              width="50"
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="uiStore.isAdmin" class="layout-container py-64">
      <button class="btn btn--primary" @click="toggleTestMode">
        <span v-if="!testOrderActive">Aktivera testläge</span>
        <span v-else>Inaktivera testläge</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import LinkHelper from '~/components/globals/LinkHelper.vue';
import { ref, computed } from 'vue';
import { useUiStore } from '~/store/ui';
const runtimeConfig = useRuntimeConfig();
const globalContentStore = useGlobalContentStore();
const footer = useGlobalContentStore().footer;

const uiStore = useUiStore();
const testModeCookie = useCookie('test-order');
const testOrderActive = ref(false);

const marketSettings = useGlobalContentStore().marketSettings;
const sharedResources = globalContentStore.sharedResources;

const toggleTestMode = (async() => {
  const value = testModeCookie.value ? false : true;

  if (testModeCookie.value) {
    testModeCookie.value = false;
  } else {
    testModeCookie.value = true;
  }

  try {
    const res = await $fetch(`${runtimeConfig.public.apiUrl}user/session/testordermode`, {
      method: 'PUT',
      body: {
        'countryCode': marketSettings.countryCode,
        'enabled': value,
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': globalContentStore.getAcceptLanguage,
        'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
      },
    });

    if (res.success) {
      testOrderActive.value = testModeCookie.value;
      emit('set-test-mode', value);
    }

  } catch (e) {
    console.log(e);
  }

});

const emit = defineEmits<{
  (event: 'set-test-mode', value: boolean): void,
}>();

onMounted(()=> {
  if (testModeCookie.value) {
    testOrderActive.value = true;
    emit('set-test-mode', true);
  }
});

defineExpose({
  toggleTestMode,
});

const isOneTrustLoaded = computed(() => {
  const url = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  if (typeof window !== 'undefined') {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src.includes(url)) return true;
    }
  }
  return false;
});

</script>
