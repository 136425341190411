<template>
  <ModalWrapper v-if="!isEpiEdit">
    <Transition name="fade" mode="out-in">
      <div>
        <div class="text-heading-large sm:text-heading-xl mb-8">{{ ageGate.title }}</div>
        <div class="wysiwyg wysiwyg--text-sm mb-16 sm:mb-20" v-html="ageGate.description" />
        <div class="mb-20">
          <div class="age-agreement-form flex">
            <div class="form-group mr-12">
              <label class="text-xsmall-medium sm:text-small-medium mb-8">{{ sharedResources.yearOfBirth }}</label>
              <div class="flex">
                <input
                  type="tel"
                  index="1"
                  class="form-group__field year"
                  placeholder="Y"
                  maxlength="1"
                  @input="focusNext(1)"
                >
                <input
                  type="tel"
                  index="2"
                  class="form-group__field year"
                  placeholder="Y"
                  maxlength="1"
                  @input="focusNext(2)"
                >
                <input
                  type="tel"
                  index="3"
                  class="form-group__field year"
                  placeholder="Y"
                  maxlength="1"
                  @input="focusNext(3)"
                >
                <input
                  type="tel"
                  index="4"
                  class="form-group__field year"
                  placeholder="Y"
                  maxlength="1"
                  @input="focusNext(4)"
                >
              </div>
            </div>
            <div class="form-group mr-12">
              <label class="text-xsmall-medium sm:text-small-medium mb-8">{{ sharedResources.month }}</label>
              <div class="flex">
                <input
                  type="tel"
                  index="5"
                  class="form-group__field month"
                  placeholder="M"
                  maxlength="1"
                  @input="focusNext(5)"
                >
                <input
                  type="tel"
                  index="6"
                  class="form-group__field month"
                  placeholder="M"
                  maxlength="1"
                  @input="focusNext(6)"
                >
              </div>
            </div>
            <div class="form-group mr-12">
              <label class="text-xsmall-medium sm:text-small-medium mb-8">{{ sharedResources.day }}</label>
              <div class="flex">
                <input
                  type="tel"
                  index="7"
                  class="form-group__field day"
                  placeholder="D"
                  maxlength="1"
                  @input="focusNext(7)"
                >
                <input
                  type="tel"
                  index="8"
                  class="form-group__field day"
                  placeholder="D"
                  maxlength="1"
                  @input="focusNext(8)"
                >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div v-if="showFieldError" class="mt-12 text-xsmall-medium text-error">
              {{ sharedResources.invalidBirthdate }}
            </div>
          </transition>
        </div>
        <div class="mb-20">
          <Checkbox
            :text="sharedResources.ageAgreementFormCheckboxText"
            @on-check="setAgreement"
          />
          <transition name="fade" mode="out-in">
            <div v-if="showAgreementError" class="mt-8 text-xsmall-medium text-error">
              {{ sharedResources.emptyFieldError }}
            </div>
            <div v-else-if="showUnder18Error" class="mt-16 text-xsmall-medium text-error">
              {{ sharedResources.under18ErrorText }}
            </div>
          </transition>
        </div>
        <button
          class="btn btn--primary w-full text-xs sm:text-sm h-40 sm:h-48"
          @click="validateForm"
        >
          {{ sharedResources.ageAgreementFormButtonText }}
        </button>
      </div>
    </Transition>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import { usePageContentStore } from '~/store/pageContent';
import { storeToRefs } from 'pinia';
import Checkbox from '~/components/form-elements/Checkbox.vue';
import useDisableScroll from '~/composables/useDisableScroll';
const { disableScroll, enableScroll } = useDisableScroll();
const { isEpiEdit } = storeToRefs(usePageContentStore());
const globalContentStore = useGlobalContentStore();
const sharedResources = globalContentStore.sharedResources;
const ageGate = globalContentStore.ageGate;
const agreement = ref(false);
const loading = ref(false);
const over18 = ref(false);
const showAgreementError = ref(false);
const showFieldError = ref(false);
const showUnder18Error = ref(false);

onMounted(()=> {
  document.body.classList.add('noscroll');
  disableScroll();
});
onBeforeUnmount(()=> {
  document.body.classList.remove('noscroll');
  enableScroll();
});

const emit = defineEmits<{
  (event: 'close-age-form'): void
}>();

const setAgreement = (value: boolean) => {
  agreement.value = value;
};

const focusNext = (index: number) => {
  const input = document.querySelector('input[index="' + (index + 1) + '"]') as HTMLInputElement;
  if (input) {
    input.focus();
  }
};

const getBirthDate = () => {
  let year = '';
  let month = '';
  let day = '';
  let birthDate = '';
  const inputsYear = document.querySelectorAll('.age-agreement-form .year') as HTMLInputElement[];
  const inputsMonth = document.querySelectorAll('.age-agreement-form .month') as HTMLInputElement[];
  const inputsDays = document.querySelectorAll('.age-agreement-form .day') as HTMLInputElement[];

  if (inputsYear && inputsYear.length > 0) {
    inputsYear.forEach((input: HTMLInputElement) => {
      if (!input.value) {
        showFieldError.value = true;
        return;
      } else {
        year += input.value;
      }
    });
  }

  if (inputsMonth && inputsMonth.length > 0) {
    inputsMonth.forEach((input: HTMLInputElement) => {
      if (!input.value) {
        showFieldError.value = true;
        return;
      } else {
        month += input.value;
      }
    });
  }

  if (inputsDays && inputsDays.length > 0) {
    inputsDays.forEach((input: HTMLInputElement) => {
      if (!input.value) {
        showFieldError.value = true;
        return;
      } else {
        day += input.value;
      }
    });
  }

  birthDate = year + '/' + month + '/' + day;
  return birthDate;
};

const validateAge = () => {
  loading.value = true;
  const typedValue = getBirthDate();
  const birthday = new Date(typedValue);
  const currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
  const age = ~~((Date.now(currentDate) - birthday) / (31557600000));

  age >= 18 ? over18.value = true : over18.value = false;
};

const validateForm = () => {
  showFieldError.value = false;
  showAgreementError.value = false;
  showUnder18Error.value = false;

  validateAge();

  if (showFieldError.value) {
    return;
  }

  if (!agreement.value) {
    showAgreementError.value = true;
    return;
  }

  if (!over18.value) {
    showUnder18Error.value = true;
  } else {
    emit('close-age-form');
  }
};

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#00374a',
    },
  ],
});

</script>
