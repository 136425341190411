export default function useResponsive() {
  const isScrolledUp = ref(true);
  const isAtTop = ref(true);
  let lastPosition = 0;
  
  onMounted(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    if (lastPosition < window.scrollY) {
      isScrolledUp.value = false;
    }

    if (lastPosition > window.scrollY) {
      isScrolledUp.value = true;
    }

    if (window.scrollY == 0) {
      isAtTop.value = true;
    } else {
      isAtTop.value = false;
    }

    setTimeout(() => {
      lastPosition = window.scrollY;
    }, 500);

  };

  return {
    isScrolledUp,
    isAtTop,
  };
}
