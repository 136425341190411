<template>
  <div>
    <SignUpVerifyEmailInformation
      v-if="showVerifyEmailConfirmation"
      :is-in-modal="true"
    />
    <div
      v-else-if="!emailSent"
      class="px-24 text-xsmall sm:p-0"
    >
      <div class="text-xsmall-medium sm:text-small-medium mb-12">
        {{ translation.loginReminderTitle }}
      </div>
      <form @submit.prevent="sendReminder">
        <div class="mb-12">
          <input
            v-model="email"
            type="email"
            :autocomplete="translation.loginEmail"
            class="form-input"
            :placeholder="sharedResources.email"
            :class="{
              'has-error form-input--error': emailError,
            }"
          >
          <div v-if="emailError" class="mt-6 text-xsmall-medium text-error">
            {{ sharedResources.emptyEmailError }}
          </div>
        </div>
        <GlobalsButton
          :text="translation.loginReminderAction"
          class="mb-12"
        />
      </form>

      <div
        v-if="showResendEmailError"
        class="bg-error mb-8 rounded-md text-white p-12 flex items-start text-xsmall sm:text-small-regular"
      >
        <ExclamationCircleIcon
          class="w-24 text-white mr-12 flex-shrink-0"
        />
        <div class="mt-[5px] md:mt-2">
          {{ translation.mustConfirmEmailAdress }}
          <div
            class="underline hover:no-underline inline cursor-pointer"
            @click="resendVerifyEmailClick"
          >
            {{ sharedResources.resendVerificationEmail }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="px-24 text-xsmall sm:p-0"
    >
      <div class="text-small-regular">
        {{ translation.loginReminderSent.replace('##EMAIL##', email) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { type IICommandResultBase } from '~/api-types';
import { useErrorStore } from '~/store/error';
import ExclamationCircleIcon from '@heroicons/vue/24/outline/esm/ExclamationCircleIcon';
import SignUpVerifyEmailInformation from '~/components/modals/SignUpVerifyEmailInformation.vue';
const globalContentStore = useGlobalContentStore();
const marketSettings = globalContentStore.marketSettings;
const translation = globalContentStore.myPageResources;
const sharedResources = globalContentStore.sharedResources;
const errorStore = useErrorStore();
const { apiPost } = useApiFetch();

interface Props {
  prefill?: string,
}
const props = withDefaults(defineProps<Props>(), {
  prefill: '',
});

const email = ref(props.prefill);
const emailSent = ref(false);
const emailError = ref(false);
const showResendEmailError = ref(false);
const showVerifyEmailConfirmation = ref(false);

const sendReminder = async() => {
  emailError.value = false;
  if (!email.value) {
    emailError.value = true;
    return;
  }

  const result = await apiPost<IICommandResultBase>(
    'user/passwordreset',
    {
      countryCode: marketSettings.countryCode,
      email: email.value,
    }
  );
  if (result.success && result.userNotVerified) {
    showResendEmailError.value = true;
  } else if (result.success) {
    emailSent.value = true;
  } else {
    errorStore.displayError({ message: result.errorMessage || 'Error' });
    window.dataLayer?.push({
      event: 'Error',
      errorMessage: result.errorMessage || 'Reset password error',
    });
  }
};

const resendVerifyEmailClick = async() => {
  const payload = {
    email: email.value,
    userId: '',
    returnUrl: '/',
  };
 
  const result = await apiPost<IICommandResultBase>(
    'user/resendverification',
    payload
  );

  if (result && result.success) {
    showVerifyEmailConfirmation.value = true;
    showResendEmailError.value = false;
  }
};

</script>
