<template>
  <ModalWrapper v-if="!isEpiEdit">
    <transition name="fade" mode="out-in">
      <div v-if="!decline">
        <div class="text-heading-large sm:text-heading-xl mb-8">{{ ageGate.title }}</div>
        <div class="wysiwyg wysiwyg--text-sm mb-16 sm:mb-20" v-html="ageGate.description" />
        <button
          class="btn btn--primary w-full mb-20 h-40 sm:h-48"
          @click="$emit('close-age')"
        >
          {{ ageGate.above18ButtonText }}
        </button>
        <button
          class="mx-auto text-black underline text-small-regular w-fit-content block hover:no-underline"
          @click="decline = true"
        >
          {{ ageGate.below18LinkText }}
        </button>
      </div>
      <div v-else>
        <div class="text-heading-large sm:text-heading-xl mb-8">{{ ageGate.below18Title }}</div>
        <div class="text-xsmall leading-xs sm:text-small-regular mb-16 sm:mb-20">{{ ageGate.below18Text }}</div>
        <div
          class="btn btn--primary h-40 sm:h-48 w-full"
          @click="decline = false"
        >
          {{ ageGate.below18ButtonText }}
        </div>
      </div>
    </transition>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import { usePageContentStore } from '~/store/pageContent';
import { storeToRefs } from 'pinia';
import useDisableScroll from '~/composables/useDisableScroll';
const { disableScroll, enableScroll } = useDisableScroll();
const { isEpiEdit } = storeToRefs(usePageContentStore());

const ageGate = useGlobalContentStore().ageGate;

const decline = ref(false);

onMounted(()=> {
  document.body.classList.add('noscroll');
  disableScroll();
});

onBeforeUnmount(()=> {
  document.body.classList.remove('noscroll');
  enableScroll();
});

defineEmits<{
  (event: 'close-age'): void
}>();

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#00374a',
    },
  ],
});

</script>
