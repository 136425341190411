<template>
  <div v-if="healthWarnings.length > 0" class="layout-container">
    <ul class="flex flex-col sm:flex-row justify-center pt-24 text-center !font-arial font-bold text-base-medium tracking-[-0.4px] sm:text-heading-lg md:font-bold sm:leading-sm sm:justify-between">
      <li 
        v-for="(healthWarning, index) in healthWarnings" 
        :key="index" 
        class="px-12 py-8 sm:py-0"
      >
        {{ healthWarning }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
const healthWarnings = useGlobalContentStore().header.healthWarnings;
</script>
