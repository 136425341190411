<template>
  <div>
    <transition name="notice-animation">
      <div
        v-if="favouriteNoticeOpen"
        class="absolute left-16 right-16 md:left-auto md:right-32 top-112 md:top-96 bg-white p-24 rounded-md shadow-pop-up md:min-w-360"
        @mouseenter="pauseFavouriteNoticeTimer"
        @mouseleave="startFavouriteNoticeTimer"
      >
        <div class="flex mb-12 items-center">
          <div class="text-sm font-medium">
            {{ favouritesResources.addedToFavourites }}
          </div>
        </div>
        <a class="btn btn--primary w-full" :href="favouritesPage">
          {{ favouritesResources.navigateToFavourites }}
        </a>
        <button class="btn btn--small-round btn--secondary absolute top-16 right-16">
          <XMarkIcon class="h-16 w-16" @click="favouriteNoticeOpen = false" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue';
import { useFavouritesStore } from '~/store/favourites';
import XMarkIcon from '@heroicons/vue/24/outline/esm/XMarkIcon';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContentStore = useGlobalContentStore();
const favouriteStore = useFavouritesStore();
const favouritesPage = globalContentStore.pageSettings.favouritesPage.url;
const favouritesResources = globalContentStore.favouritesResources;

const unsubscribe = favouriteStore.$onAction(
  ({ after, args, name }) => {
    after(() => {
      if (name == 'addToFavourites') {
        openFavouriteNotice();
      }
    });
  }
);
type timer = ReturnType<typeof setTimeout>;
let favouriteNoticeTimer: timer;

const favouriteNoticeOpen = ref(false);

const openFavouriteNotice = () => {
  favouriteNoticeOpen.value = true;
  startFavouriteNoticeTimer();
};

const startFavouriteNoticeTimer = () => {
  favouriteNoticeTimer = setTimeout(() => favouriteNoticeOpen.value = false, 2000);
};

const pauseFavouriteNoticeTimer = () => {
  clearTimeout(favouriteNoticeTimer);
};

onBeforeUnmount(() => unsubscribe());
</script>

<style>
.notice-animation-enter-active,
.notice-animation-leave-active {
  transition: all 0.3s ease-out;
}

.notice-animation-enter-from,
.notice-animation-leave-to {
  opacity: 0;
  transform: translateY(15%);
}
</style>
